import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Carousel from "react-bootstrap/Carousel"
import Table from "react-bootstrap/Table"
import Accordion from "react-bootstrap/Accordion"

import Title from "../components/title"
import SEO from "../components/seo"
import Registration from "../components/registration"

import { BsGenderFemale } from "react-icons/bs"
import { BsGenderMale } from "react-icons/bs"
import { GiCheckMark } from "react-icons/gi"

const KittensPage = (props) => {
  // console.log(props.data.catsData.edges.findIndex((kitten, i) => kitten.node.status === 1))
  return (
    <Layout>
      <SEO title="Kitten | Leopardis" />
      <main style={{ background: "#FFFEFD" }}>
        <Container fluid="sm" className="mb-3 mb-md-5">
          <Title title="Abgabeinfo" />

          <Row className="justify-content-center px-1 px-sm-3 px-lg-5">
            <Col sm={10} md={8} className="my-2">
              <p>
                Unsere <TextDeco>Kätzchen</TextDeco> dürfen nicht vor Ablauf der 15. Lebenswoche in ihr neues Zuhause umziehen. Zu diesem Zeitpunkt sind sie von der Mutter entwöhnt, jedoch gut sozialisiert und entsprechend erzogen. Sie sind stubenrein, kennen sämtliche Alltagsgeräusche und sind an Nass- sowie Trockenfutter gewöhnt.
              </p>
            </Col>

            <Col sm={10} md={8} className="my-2">
              <p>
                Unsere Kätzchen werden <TextDeco>in gesundem Zustand </TextDeco> übergeben. Bei Abgabe sind die Kitten zweifach gegen Katzenschnupfen und Katzenseuche geimpft sowie mehrfach entwurmt. Sie sind auf Erbkrankheiten getestet und wurden regelmäßig tierärztlich untersucht.
              </p>
            </Col>
          </Row>

          {props.data.catsData.edges.find((kitten) => kitten.node.status === 1) ? (
            <Title title="Kitten: aktueller Wurf" />
          ) : (
            <>
              <Title title="Momentan gibt es keine Katzenwelpen zur Vermittlung." />
              <Registration />
            </>
          )}

          <Row className="my-2 mb-4">
            {props.data.catsData.edges.map((kitten, i) => (
              <React.Fragment key={i}>
                {kitten.node.status === 1 ? (
                  <Col xs={12} md={6} className="my-3 mb-4" key={kitten.node.name}>
                    <Card key={i} style={{ background: "#FFF4E5" }}>
                      <Carousel interval={null}>
                        {kitten.node.photos.map((photo, i) => (
                          <Carousel.Item key={i}>
                            <GatsbyImage image={photo.gatsbyImageData} alt={kitten.node.name} />
                          </Carousel.Item>
                        ))}
                      </Carousel>

                      <CatName>{kitten.node.name}</CatName>
                      <Card.Body>
                        <Table hover size="sm">
                          <tbody>
                            <tr>
                              <TableLeftColumn>Geschlecht</TableLeftColumn>
                              <TableRightColumn>
                                {kitten.node.geschlecht === 1 ? "Männchen" : "Weibchen"} {kitten.node.geschlecht === 1 ? <BsGenderMale /> : <BsGenderFemale />}
                              </TableRightColumn>
                            </tr>
                            <tr>
                              <TableLeftColumn>Geburtsort</TableLeftColumn>
                              <TableRightColumn>Giengen an der Brenz</TableRightColumn>
                            </tr>
                            <tr>
                              <TableLeftColumn>Geburtstag</TableLeftColumn>
                              <TableRightColumn>{kitten.node.geburtstag}</TableRightColumn>
                            </tr>
                            <tr>
                              <TableLeftColumn>Fellfarbe</TableLeftColumn>
                              <TableRightColumn>{kitten.node.fellfarbe}</TableRightColumn>
                            </tr>
                            <tr>
                              <TableLeftColumn>Augenfarbe</TableLeftColumn>
                              <TableRightColumn>{kitten.node.augenfarbe}</TableRightColumn>
                            </tr>
                          </tbody>
                        </Table>

                        <Accordion className="my-3 px-sm-3 px-md-5">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Gesundheit</Accordion.Header>

                            <Accordion.Body>
                              <Table hover size="sm">
                                <tbody>
                                  {/* {kitten.node.fiv === 1 && ( */}
                                  <tr>
                                    <TableLeftColumn>FIV</TableLeftColumn>
                                    <TableRightColumn>
                                      <GiCheckMark />
                                    </TableRightColumn>
                                  </tr>
                                  {/* )} */}
                                  {/* {kitten.node.felv === 1 && ( */}
                                  <tr>
                                    <TableLeftColumn>FeLV</TableLeftColumn>
                                    <TableRightColumn>
                                      <GiCheckMark />
                                    </TableRightColumn>
                                  </tr>
                                  {/* )} */}
                                  {/* {kitten.node.hcm === 1 && ( */}
                                  <tr>
                                    <TableLeftColumn>HCM</TableLeftColumn>
                                    <TableRightColumn>
                                      <GiCheckMark />
                                    </TableRightColumn>
                                  </tr>
                                  {/* )}
                                  {kitten.node.pkdef === 1 && ( */}
                                  <tr>
                                    <TableLeftColumn>PKDEF</TableLeftColumn>
                                    <TableRightColumn>
                                      <GiCheckMark />
                                    </TableRightColumn>
                                  </tr>
                                  {/* )}
                                  {kitten.node.pkdgen === 1 && ( */}
                                  <tr>
                                    <TableLeftColumn>PKD gen</TableLeftColumn>
                                    <TableRightColumn>
                                      <GiCheckMark />
                                    </TableRightColumn>
                                  </tr>
                                  {/* )}
                                  {kitten.node.prab === 1 && ( */}
                                  <tr>
                                    <TableLeftColumn>PRA-B</TableLeftColumn>
                                    <TableRightColumn>
                                      <GiCheckMark />
                                    </TableRightColumn>
                                  </tr>
                                  {/* )}
                                  {kitten.node.entwurmt === 1 && ( */}
                                  <tr>
                                    <TableLeftColumn>entwurmt</TableLeftColumn>
                                    <TableRightColumn>
                                      <GiCheckMark />
                                    </TableRightColumn>
                                  </tr>
                                  {/* )}
                                  {kitten.node.mikrochip === 1 && (
                                    <tr>
                                      <TableLeftColumn>Mikrochip</TableLeftColumn>
                                      <TableRightColumn>
                                        <GiCheckMark />
                                      </TableRightColumn>
                                    </tr>
                                  )} */}
                                </tbody>
                              </Table>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="1">
                            <Accordion.Header>Kitteneltern</Accordion.Header>
                            <Accordion.Body>
                              <Table hover size="sm">
                                <tbody>
                                  <tr>
                                    <TableLeftColumn>Mutter</TableLeftColumn>
                                    <TableRightColumn>
                                      <Link to={"/#" + kitten.node.mutter}> {kitten.node.mutter}</Link>
                                    </TableRightColumn>
                                  </tr>
                                  <tr>
                                    <TableLeftColumn>Vater</TableLeftColumn>
                                    <TableRightColumn>
                                      <Link to={"/#" + kitten.node.vater}>{kitten.node.vater}</Link>
                                    </TableRightColumn>
                                  </tr>
                                </tbody>
                              </Table>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Card.Body>
                    </Card>
                  </Col>
                ) : null}
              </React.Fragment>
            ))}
          </Row>
        </Container>
      </main>
    </Layout>
  )
}

const CatName = styled(Card.Title)`
  text-align: center;
  font-family: "Pacifico", cursive;
  font-size: 3rem !important;

  background: #ffb347;
  background: -webkit-linear-gradient(to bottom, #ffcc33, #ffb347);
  background: linear-gradient(to bottom, #ffcc33, #ffb347);
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  text-shadow: 0 0 1px transparent;
`

const TextDeco = styled.span`
  font-family: "Pacifico";
  color: #fbb034;

  letter-spacing: 1px;
`

const TableLeftColumn = styled.td`
  width: 50%;
  text-align: center;
`

const TableRightColumn = styled.td`
  width: 50%;
  text-align: center;

  a {
    text-decoration: none;
  }
`

export default KittensPage

export const getCats = graphql`
  query {
    catsData: allContentfulKatzen {
      edges {
        node {
          id
          name
          status
          geschlecht
          fellfarbe
          augenfarbe
          mutter
          vater
          geburtstag(formatString: "DD.MM.YYYY")
          photos {
            gatsbyImageData
          }
        }
      }
    }
  }
`

// fiv
// felv
// hcm
// pkdgen
// pkdef
// prab
// entwurmt
// mikrochip
// profilbild {
//   gatsbyImageData
// }
